@import "../colors";

.navbar {
  background-color: $color_3;
  button {
    display: flex;
    width: 80px;
    height: 80px;
    border-radius: 3px;
    flex-flow: column;
    background-color: $color_6;
    transition: 0.2s;
    &:last-child {
      margin-top: 3rem;
    }
    &:hover:not(.selected) {
      svg {
        > path {
          stroke: $white !important;
        }
      }
      small {
        color: $white !important;
      }
    }
    small {
      font-size: 0.7rem;
      margin-top: 0.5rem;
      font-weight: bold;
      color: $color_7;
    }
  }
  .selected {
    background-color: $color_7;
    svg {
      > path {
        stroke: $color_6 !important;
      }
    }
    small {
      color: $color_6 !important;
    }
  }
}
