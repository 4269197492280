@import '../colors';

.page-header{
  border-bottom: 1px solid $color_5;
  strong{
    color:$color_4;
    font-size: 1.6rem;
  }
  h1{
    color: $white;
    font-size: 1.6rem;
  }
  button{
    background-color: $black;
    color:$color_4;
    border-radius: 3px;
  }
}