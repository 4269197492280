@import '../colors';

.message{
  bottom:0;
  left:50%;
  transform: translate(-50%,0);
  border-radius: 10px 10px 0 0;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.success{
  background-color: $success;
  color:$white
}
.danger{
  background-color: $danger;
  color:$white
}
.warning{
  background-color: $warning;
  color:$black
}
.info{
  background-color: $info;
  color:$white
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translate(-50%,1000px);
            transform: translate(-50%,1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%,0);
            transform: translate(-50%,0);
    opacity: 1;
  }
}