.d-flex{ display: flex; }
.d-none{ display: none; }
.d-block{ display: block; }
.d-inline{ display: inline; }
.d-inline-block{ display: inline-block; }
.d-inline-flex{ display: inline-flex;}
.d-table{ display: table; }
.d-table-cell{ display: table-cell; }
.d-table-row{ display: table-row; }

@include media-breakpoint-up(md) {
  .d-md-flex{ display: flex; }
  .d-md-none{ display: none; }
  .d-md-block{ display: block; }
  .d-md-inline{ display: inline; }
  .d-md-inline-block{ display: inline-block; }
  .d-md-inline-flex{ display: inline-flex;}
  .d-md-table{ display: table; }
  .d-md-table-cell{ display: table-cell; }
  .d-md-table-row{ display: table-row; }
}

@include media-breakpoint-up(lg) {
  .d-lg-flex{ display: flex; }
  .d-lg-none{ display: none; }
  .d-lg-block{ display: block; }
  .d-lg-inline{ display: inline; }
  .d-lg-inline-block{ display: inline-block; }
  .d-lg-inline-flex{ display: inline-flex;}
  .d-lg-table{ display: table; }
  .d-lg-table-cell{ display: table-cell; }
  .d-lg-table-row{ display: table-row; }
}

@include media-breakpoint-up(xl) {
  .d-xl-flex{ display: flex; }
  .d-xl-none{ display: none; }
  .d-xl-block{ display: block; }
  .d-xl-inline{ display: inline; }
  .d-xl-inline-block{ display: inline-block; }
  .d-xl-inline-flex{ display: inline-flex;}
  .d-xl-table{ display: table; }
  .d-xl-table-cell{ display: table-cell; }
  .d-xl-table-row{ display: table-row; }
}

@include media-breakpoint-up(xxl) {
  .d-xxl-flex{ display: flex; }
  .d-xxl-none{ display: none; }
  .d-xxl-block{ display: block; }
  .d-xxl-inline{ display: inline; }
  .d-xxl-inline-block{ display: inline-block; }
  .d-xxl-inline-flex{ display: inline-flex;}
  .d-xxl-table{ display: table; }
  .d-xxl-table-cell{ display: table-cell; }
  .d-xxl-table-row{ display: table-row; }
}