@import "../colors";

.overlay-create {
  .obs-link-title {
    color: $white;
    text-align: center;
  }
  > strong {
    font-size: 1.5rem;
    color: $white;
  }
  .generate-link-button {
    white-space: nowrap;
    border-radius: 3px;
    font-size: 1.2rem;
    background-color: #00ff6d;
  }
  .generate-preview-button {
    white-space: nowrap;
    border: 2px solid $danger;
    background-color: transparent;
    color: $danger;
    font-size: 1.2rem;
    border-radius: 3px;
  }
}
