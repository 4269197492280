.obs-overlay {
  top: 0;
  left: 0;
}
.top_left {
  top: 0;
  left: 0;
}
.top_right {
  top: 0;
  right: 0;
}
.bottom_left {
  bottom: 0;
  left: 0;
}
.bottom_right {
  bottom: 0;
  right: 0;
}
.center_center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-container {
  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);
    pointer-events: none;
    filter: saturate(0%);
    border: none;
  }
}
@media (max-aspect-ratio: 16 / 9) {
  .video-container {
    iframe {
      width: 177.78vh;
    }
  }
}
