/*COLORS*/
$black: #000000;
$white: #ffffff;

$danger: #f17171;
$success: #28a745;
$warning: #d39e00;
$info: #007bff;

$color_1: #121212;
$color_2: #202020;
$color_3: #353535;
$color_4: #9c76ff;
$color_5: #5e5e5e;
$color_6: #2a2a2a;
$color_7: #7e7e7e;
$color_8: #202020;
$color_9: #cccccc;
