.overflow-auto{ overflow:auto; }
.overflow-hidden{ overflow: hidden; }

@include media-breakpoint-up(md) {
  .overflow-md-auto{ overflow:auto; }
  .overflow-md-hidden{ overflow: hidden; }
}

@include media-breakpoint-up(lg) {
  .overflow-lg-auto{ overflow:auto; }
  .overflow-lg-hidden{ overflow: hidden; }
}

@include media-breakpoint-up(xl) {
  .overflow-xl-auto{ overflow:auto; }
  .overflow-xl-hidden{ overflow: hidden; }
}

@include media-breakpoint-up(xxl) {
  .overflow-xxl-auto{ overflow:auto; }
  .overflow-xxl-hidden{ overflow: hidden; }
}