@import "../colors";

.ad_unit_info {
  span {
    color: $white;
    background-color: $color_2;
    border-radius: 3px;
  }

  &:last-child {
    .delete {
      background-color: $color_6;
      min-width: 40px;
      min-height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .collapsible {
      background-color: $color_2;
      color: $white;
      text-align: left;
      border-radius: 5px 5px 0 0;
      .arrow-panel {
        transform-origin: center center;
        transition: 0.2s;
      }
    }
    .panel-open {
      .arrow-panel {
        transform: rotate(-180deg);
      }
    }
    .content {
      background-color: $color_3;
      border-radius: 0 0 5px 5px;
      > div {
        > div {
          background-color: $color_1;
        }
      }

      .delete-screen {
        pointer-events: all;
        right: 5px;
        top: 5px;
      }
    }
  }

  .total-views {
    color: $white;
    font-size: 1.2rem;
  }
}
