@import "../colors";

.ad-unit-row {
  > div {
    padding: 1rem 1rem;
    font-weight: 600;
    width: 100%;
    background-color: $color_8;
    border-radius: 3px;
    color: $color_9;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    > div {
      display: flex;
      align-items: center;
      text-align: center;
      margin-right: 0.5rem;
      padding-right: 0.5rem;
      width: 100%;
      height: 60px;
      border-right: 1px solid darken($color_9, 50%);
      span {
        font-size: 0.9rem;
        text-align: center;
        min-width: 60px;
        color: $color_9;
      }
      strong {
        font-size: 0.9rem;
        text-align: center;
        min-width: 60px;
        color: $color_9;
      }

      &:last-child {
        width: 100%;
        border-right: none;
        margin-right: 0;
        padding-right: 0;
      }
    }
    .customer {
      min-width: 150px;
      max-width: 150px;
    }
    .title {
      min-width: 250px;
      max-width: 250px;
    }
    button {
      background-color: $color_6;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
