
.w-5{width:5%}
.w-10{width:10%}
.w-15{width:15%}
.w-20{width:20%}
.w-25{width:25%}
.w-30{width:30%}
.w-35{width:35%}
.w-40{width:40%}
.w-45{width:45%}
.w-50{width:50%}
.w-55{width:55%}
.w-60{width:60%}
.w-65{width:65%}
.w-70{width:70%}
.w-75{width:75%}
.w-80{width:80%}
.w-85{width:85%}
.w-90{width:90%}
.w-95{width:95%}
.w-100{width:100%}
.w-auto{width:auto}

@include media-breakpoint-up(md) {
  .w-md-5{width:5%;}
  .w-md-10{width:10%;}
  .w-md-15{width:15%;}
  .w-md-20{width:20%;}
  .w-md-25{width:25%;}
  .w-md-30{width:30%;}
  .w-md-35{width:35%;}
  .w-md-40{width:40%;}
  .w-md-45{width:45%;}
  .w-md-50{width:50%;}
  .w-md-55{width:55%;}
  .w-md-60{width:60%;}
  .w-md-65{width:65%;}
  .w-md-70{width:70%;}
  .w-md-75{width:75%;}
  .w-md-80{width:80%;}
  .w-md-85{width:85%;}
  .w-md-90{width:90%;}
  .w-md-95{width:95%;}
  .w-md-100{width:100%;}
  .w-auto{width:auto}
}

@include media-breakpoint-up(lg) {
  .w-lg-5{width:5%;}
  .w-lg-10{width:10%;}
  .w-lg-15{width:15%;}
  .w-lg-20{width:20%;}
  .w-lg-25{width:25%;}
  .w-lg-30{width:30%;}
  .w-lg-35{width:35%;}
  .w-lg-40{width:40%;}
  .w-lg-45{width:45%;}
  .w-lg-50{width:50%;}
  .w-lg-55{width:55%;}
  .w-lg-60{width:60%;}
  .w-lg-65{width:65%;}
  .w-lg-70{width:70%;}
  .w-lg-75{width:75%;}
  .w-lg-80{width:80%;}
  .w-lg-85{width:85%;}
  .w-lg-90{width:90%;}
  .w-lg-95{width:95%;}
  .w-lg-100{width:100%;}
  .w-auto{width:auto}
}

@include media-breakpoint-up(xl) {
  .w-xl-5{width:5%;}
  .w-xl-10{width:10%;}
  .w-xl-15{width:15%;}
  .w-xl-20{width:20%;}
  .w-xl-25{width:25%;}
  .w-xl-30{width:30%;}
  .w-xl-35{width:35%;}
  .w-xl-40{width:40%;}
  .w-xl-45{width:45%;}
  .w-xl-50{width:50%;}
  .w-xl-55{width:55%;}
  .w-xl-60{width:60%;}
  .w-xl-65{width:65%;}
  .w-xl-70{width:70%;}
  .w-xl-75{width:75%;}
  .w-xl-80{width:80%;}
  .w-xl-85{width:85%;}
  .w-xl-90{width:90%;}
  .w-xl-95{width:95%;}
  .w-xl-100{width:100%;}
  .w-auto{width:auto}
}

@include media-breakpoint-up(xxl) {
  .w-xxl-5{width:5%;}
  .w-xxl-10{width:10%;}
  .w-xxl-15{width:15%;}
  .w-xxl-20{width:20%;}
  .w-xxl-25{width:25%;}
  .w-xxl-30{width:30%;}
  .w-xxl-35{width:35%;}
  .w-xxl-40{width:40%;}
  .w-xxl-45{width:45%;}
  .w-xxl-50{width:50%;}
  .w-xxl-55{width:55%;}
  .w-xxl-60{width:60%;}
  .w-xxl-65{width:65%;}
  .w-xxl-70{width:70%;}
  .w-xxl-75{width:75%;}
  .w-xxl-80{width:80%;}
  .w-xxl-85{width:85%;}
  .w-xxl-90{width:90%;}
  .w-xxl-95{width:95%;}
  .w-xxl-100{width:100%;}
  .w-auto{width:auto}
}