@import '../colors';

.modal{
  top:0;
  left:0;
  background-color:rgba(0,0,0,0.4);
  > div{
    top:40%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: 10px;
    background-color: $color_3;
    > p{
      color:$white;
    }
    button{
      background-color: $color_4;
      border-radius: 3px;
      color:$white;
    }
  }
}