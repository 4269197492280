@import "../colors";

.search-button {
  color: $color_4;
  font-weight: bold;
  background-color: $black;
  border-radius: 0 3px 3px 0;
}

.table {
  border-collapse: separate;
  border-spacing: 1px 1rem;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  font-weight: bold;
  width: 100%;

  thead {
    width: 100%;

    > tr {
      font-weight: 500;
      width: 100%;
      color: $color_9;
      background-color: $color_1;

      th {
        width: 100%;
        text-align: left;
        padding: 0.7rem 1.2rem;

        > div {
          align-items: center;
          display: flex;

          strong {
            white-space: nowrap;
          }
        }
        .dropdown-filter {
          border: 1px solid $color_5;
          background-color: $color_2;
          display: flex;
          flex-flow: column;
          border-radius: 5px;
          top: 100%;
          right: 50%;
          max-height: 100px;
          overflow-y: auto;
          > span {
            &:hover {
              background-color: $color_4;
            }
          }
        }
      }
    }
  }

  tbody {
    width: 100%;

    .deleted {
      background-color: rgba($color_2, 0.3) !important;

      td:not(td:last-child) {
        opacity: 0.3;
      }
      td:last-child {
        .action-area {
          button:not(.recover-button) {
            opacity: 0.3;
          }
        }
      }
    }

    > tr {
      font-size: 0.9rem;
      font-weight: 600;
      width: 100%;
      background-color: $color_8;
      border-radius: 3px;
      color: $color_9;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

      td {
        width: 100%;
        padding: 1.5rem 1.2rem;

        > span {
          white-space: nowrap;
        }

        .action-area {
          margin-left: auto;
          width: fit-content;
          display: flex;

          button {
            background-color: $color_6;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        &:first-child {
          border-radius: 5px 0 0 5px;
        }

        &:last-child {
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }
}
