.m-0 { margin:0; }
.m-1 { margin:.25rem; }
.m-2 { margin:.5rem; }
.m-3 { margin:1rem; }
.m-4 { margin:1.5rem; }
.m-5 { margin:3rem; }

.mt-0 { margin-top:0; }
.mr-0 { margin-right:0; }
.mb-0 { margin-bottom:0; }
.ml-0 { margin-left:0; }
.mx-0 { margin-left:0 ;margin-right:0 ; }
.my-0 { margin-top:0;margin-bottom:0; }

.mt-1 { margin-top:.25rem; }
.mr-1 { margin-right:.25rem; }
.mb-1 { margin-bottom:.25rem; }
.ml-1 { margin-left:.25rem; }
.mx-1 { margin-left:.25rem;margin-right:.25rem; }
.my-1 { margin-top:.25rem;margin-bottom:.25rem; }

.mt-2 { margin-top:.5rem; }
.mr-2 { margin-right:.5rem; }
.mb-2 { margin-bottom:.5rem; }
.ml-2 { margin-left:.5rem; }
.mx-2 { margin-right:.5rem;margin-left:.5rem; }
.my-2 { margin-top:.5rem;margin-bottom:.5rem; }

.mt-3 { margin-top:1rem; }
.mr-3 { margin-right:1rem; }
.mb-3 { margin-bottom:1rem; }
.ml-3 { margin-left:1rem; }
.mx-3 { margin-right:1rem;margin-left:1rem; }
.my-3 { margin-bottom:1rem;margin-top:1rem; }

.mt-4 { margin-top:1.5rem; }
.mr-4 { margin-right:1.5rem; }
.mb-4 { margin-bottom:1.5rem; }
.ml-4 { margin-left:1.5rem; }
.mx-4 { margin-right:1.5rem;margin-left:1.5rem; }
.my-4 { margin-top:1.5rem;margin-bottom:1.5rem; }

.mt-5 { margin-top:3rem; }
.mr-5 { margin-right:3rem; }
.mb-5 { margin-bottom:3rem; }
.ml-5 { margin-left:3rem; }
.mx-5 { margin-right:3rem;margin-left:3rem; }
.my-5 { margin-top:3rem;margin-bottom:3rem; }

.mt-auto { margin-top:auto; }
.mr-auto { margin-right:auto; }
.mb-auto { margin-bottom:auto; }
.ml-auto { margin-left:auto; }
.mx-auto { margin-right:auto;margin-left:auto; }
.my-auto { margin-bottom:auto;margin-top:auto; }

@include media-breakpoint-up(md) {
  .m-md-0 { margin:0; }
  .m-md-1 { margin:.25rem; }
  .m-md-2 { margin:.5rem; }
  .m-md-3 { margin:1rem; }
  .m-md-4 { margin:1.5rem; }
  .m-md-5 { margin:3rem; }

  .mt-md-0 { margin-top:0; }
  .mr-md-0 { margin-right:0; }
  .mb-md-0 { margin-bottom:0; }
  .ml-md-0 { margin-left:0; }
  .mx-md-0 { margin-left:0 ;margin-right:0 ; }
  .my-md-0 { margin-top:0;margin-bottom:0; }

  .mt-md-1 { margin-top:.25rem; }
  .mr-md-1 { margin-right:.25rem; }
  .mb-md-1 { margin-bottom:.25rem; }
  .ml-md-1 { margin-left:.25rem; }
  .mx-md-1 { margin-left:.25rem;margin-right:.25rem; }
  .my-md-1 { margin-top:.25rem;margin-bottom:.25rem; }

  .mt-md-2 { margin-top:.5rem; }
  .mr-md-2 { margin-right:.5rem; }
  .mb-md-2 { margin-bottom:.5rem; }
  .ml-md-2 { margin-left:.5rem; }
  .mx-md-2 { margin-right:.5rem;margin-left:.5rem; }
  .my-md-2 { margin-top:.5rem;margin-bottom:.5rem; }

  .mt-md-3 { margin-top:1rem; }
  .mr-md-3 { margin-right:1rem; }
  .mb-md-3 { margin-bottom:1rem; }
  .ml-md-3 { margin-left:1rem; }
  .mx-md-3 { margin-right:1rem;margin-left:1rem; }
  .my-md-3 { margin-bottom:1rem;margin-top:1rem; }

  .mt-md-4 { margin-top:1.5rem; }
  .mr-md-4 { margin-right:1.5rem; }
  .mb-md-4 { margin-bottom:1.5rem; }
  .ml-md-4 { margin-left:1.5rem; }
  .mx-md-4 { margin-right:1.5rem;margin-left:1.5rem; }
  .my-md-4 { margin-top:1.5rem;margin-bottom:1.5rem; }

  .mt-md-5 { margin-top:3rem; }
  .mr-md-5 { margin-right:3rem; }
  .mb-md-5 { margin-bottom:3rem; }
  .ml-md-5 { margin-left:3rem; }
  .mx-md-5 { margin-right:3rem;margin-left:3rem; }
  .my-md-5 { margin-top:3rem;margin-bottom:3rem; }

  .mt-md-auto { margin-top:auto; }
  .mr-md-auto { margin-right:auto; }
  .mb-md-auto { margin-bottom:auto; }
  .ml-md-auto { margin-left:auto; }
  .mx-md-auto { margin-right:auto;margin-left:auto; }
  .my-md-auto { margin-bottom:auto;margin-top:auto; }
}

@include media-breakpoint-up(lg) {
  .m-lg-0 { margin:0; }
  .m-lg-1 { margin:.25rem; }
  .m-lg-2 { margin:.5rem; }
  .m-lg-3 { margin:1rem; }
  .m-lg-4 { margin:1.5rem; }
  .m-lg-5 { margin:3rem; }

  .mt-lg-0 { margin-top:0; }
  .mr-lg-0 { margin-right:0; }
  .mb-lg-0 { margin-bottom:0; }
  .ml-lg-0 { margin-left:0; }
  .mx-lg-0 { margin-left:0 ;margin-right:0 ; }
  .my-lg-0 { margin-top:0;margin-bottom:0; }

  .mt-lg-1 { margin-top:.25rem; }
  .mr-lg-1 { margin-right:.25rem; }
  .mb-lg-1 { margin-bottom:.25rem; }
  .ml-lg-1 { margin-left:.25rem; }
  .mx-lg-1 { margin-left:.25rem;margin-right:.25rem; }
  .my-lg-1 { margin-top:.25rem;margin-bottom:.25rem; }

  .mt-lg-2 { margin-top:.5rem; }
  .mr-lg-2 { margin-right:.5rem; }
  .mb-lg-2 { margin-bottom:.5rem; }
  .ml-lg-2 { margin-left:.5rem; }
  .mx-lg-2 { margin-right:.5rem;margin-left:.5rem; }
  .my-lg-2 { margin-top:.5rem;margin-bottom:.5rem; }

  .mt-lg-3 { margin-top:1rem; }
  .mr-lg-3 { margin-right:1rem; }
  .mb-lg-3 { margin-bottom:1rem; }
  .ml-lg-3 { margin-left:1rem; }
  .mx-lg-3 { margin-right:1rem;margin-left:1rem; }
  .my-lg-3 { margin-bottom:1rem;margin-top:1rem; }

  .mt-lg-4 { margin-top:1.5rem; }
  .mr-lg-4 { margin-right:1.5rem; }
  .mb-lg-4 { margin-bottom:1.5rem; }
  .ml-lg-4 { margin-left:1.5rem; }
  .mx-lg-4 { margin-right:1.5rem;margin-left:1.5rem; }
  .my-lg-4 { margin-top:1.5rem;margin-bottom:1.5rem; }

  .mt-lg-5 { margin-top:3rem; }
  .mr-lg-5 { margin-right:3rem; }
  .mb-lg-5 { margin-bottom:3rem; }
  .ml-lg-5 { margin-left:3rem; }
  .mx-lg-5 { margin-right:3rem;margin-left:3rem; }
  .my-lg-5 { margin-top:3rem;margin-bottom:3rem; }

  .mt-lg-auto { margin-top:auto; }
  .mr-lg-auto { margin-right:auto; }
  .mb-lg-auto { margin-bottom:auto; }
  .ml-lg-auto { margin-left:auto; }
  .mx-lg-auto { margin-right:auto;margin-left:auto; }
  .my-lg-auto { margin-bottom:auto;margin-top:auto; }
}

@include media-breakpoint-up(xl) {
  .m-xl-0 { margin:0; }
  .m-xl-1 { margin:.25rem; }
  .m-xl-2 { margin:.5rem; }
  .m-xl-3 { margin:1rem; }
  .m-xl-4 { margin:1.5rem; }
  .m-xl-5 { margin:3rem; }

  .mt-xl-0 { margin-top:0; }
  .mr-xl-0 { margin-right:0; }
  .mb-xl-0 { margin-bottom:0; }
  .ml-xl-0 { margin-left:0; }
  .mx-xl-0 { margin-left:0 ;margin-right:0 ; }
  .my-xl-0 { margin-top:0;margin-bottom:0; }

  .mt-xl-1 { margin-top:.25rem; }
  .mr-xl-1 { margin-right:.25rem; }
  .mb-xl-1 { margin-bottom:.25rem; }
  .ml-xl-1 { margin-left:.25rem; }
  .mx-xl-1 { margin-left:.25rem;margin-right:.25rem; }
  .my-xl-1 { margin-top:.25rem;margin-bottom:.25rem; }

  .mt-xl-2 { margin-top:.5rem; }
  .mr-xl-2 { margin-right:.5rem; }
  .mb-xl-2 { margin-bottom:.5rem; }
  .ml-xl-2 { margin-left:.5rem; }
  .mx-xl-2 { margin-right:.5rem;margin-left:.5rem; }
  .my-xl-2 { margin-top:.5rem;margin-bottom:.5rem; }

  .mt-xl-3 { margin-top:1rem; }
  .mr-xl-3 { margin-right:1rem; }
  .mb-xl-3 { margin-bottom:1rem; }
  .ml-xl-3 { margin-left:1rem; }
  .mx-xl-3 { margin-right:1rem;margin-left:1rem; }
  .my-xl-3 { margin-bottom:1rem;margin-top:1rem; }

  .mt-xl-4 { margin-top:1.5rem; }
  .mr-xl-4 { margin-right:1.5rem; }
  .mb-xl-4 { margin-bottom:1.5rem; }
  .ml-xl-4 { margin-left:1.5rem; }
  .mx-xl-4 { margin-right:1.5rem;margin-left:1.5rem; }
  .my-xl-4 { margin-top:1.5rem;margin-bottom:1.5rem; }

  .mt-xl-5 { margin-top:3rem; }
  .mr-xl-5 { margin-right:3rem; }
  .mb-xl-5 { margin-bottom:3rem; }
  .ml-xl-5 { margin-left:3rem; }
  .mx-xl-5 { margin-right:3rem;margin-left:3rem; }
  .my-xl-5 { margin-top:3rem;margin-bottom:3rem; }

  .mt-xl-auto { margin-top:auto; }
  .mr-xl-auto { margin-right:auto; }
  .mb-xl-auto { margin-bottom:auto; }
  .ml-xl-auto { margin-left:auto; }
  .mx-xl-auto { margin-right:auto;margin-left:auto; }
  .my-xl-auto { margin-bottom:auto;margin-top:auto; }
}

@include media-breakpoint-up(xxl) {
  .m-xxl-0 { margin:0; }
  .m-xxl-1 { margin:.25rem; }
  .m-xxl-2 { margin:.5rem; }
  .m-xxl-3 { margin:1rem; }
  .m-xxl-4 { margin:1.5rem; }
  .m-xxl-5 { margin:3rem; }

  .mt-xxl-0 { margin-top:0; }
  .mr-xxl-0 { margin-right:0; }
  .mb-xxl-0 { margin-bottom:0; }
  .ml-xxl-0 { margin-left:0; }
  .mx-xxl-0 { margin-left:0 ;margin-right:0 ; }
  .my-xxl-0 { margin-top:0;margin-bottom:0; }

  .mt-xxl-1 { margin-top:.25rem; }
  .mr-xxl-1 { margin-right:.25rem; }
  .mb-xxl-1 { margin-bottom:.25rem; }
  .ml-xxl-1 { margin-left:.25rem; }
  .mx-xxl-1 { margin-left:.25rem;margin-right:.25rem; }
  .my-xxl-1 { margin-top:.25rem;margin-bottom:.25rem; }

  .mt-xxl-2 { margin-top:.5rem; }
  .mr-xxl-2 { margin-right:.5rem; }
  .mb-xxl-2 { margin-bottom:.5rem; }
  .ml-xxl-2 { margin-left:.5rem; }
  .mx-xxl-2 { margin-right:.5rem;margin-left:.5rem; }
  .my-xxl-2 { margin-top:.5rem;margin-bottom:.5rem; }

  .mt-xxl-3 { margin-top:1rem; }
  .mr-xxl-3 { margin-right:1rem; }
  .mb-xxl-3 { margin-bottom:1rem; }
  .ml-xxl-3 { margin-left:1rem; }
  .mx-xxl-3 { margin-right:1rem;margin-left:1rem; }
  .my-xxl-3 { margin-bottom:1rem;margin-top:1rem; }

  .mt-xxl-4 { margin-top:1.5rem; }
  .mr-xxl-4 { margin-right:1.5rem; }
  .mb-xxl-4 { margin-bottom:1.5rem; }
  .ml-xxl-4 { margin-left:1.5rem; }
  .mx-xxl-4 { margin-right:1.5rem;margin-left:1.5rem; }
  .my-xxl-4 { margin-top:1.5rem;margin-bottom:1.5rem; }

  .mt-xxl-5 { margin-top:3rem; }
  .mr-xxl-5 { margin-right:3rem; }
  .mb-xxl-5 { margin-bottom:3rem; }
  .ml-xxl-5 { margin-left:3rem; }
  .mx-xxl-5 { margin-right:3rem;margin-left:3rem; }
  .my-xxl-5 { margin-top:3rem;margin-bottom:3rem; }

  .mt-xxl-auto { margin-top:auto; }
  .mr-xxl-auto { margin-right:auto; }
  .mb-xxl-auto { margin-bottom:auto; }
  .ml-xxl-auto { margin-left:auto; }
  .mx-xxl-auto { margin-right:auto;margin-left:auto; }
  .my-xxl-auto { margin-bottom:auto;margin-top:auto; }
}