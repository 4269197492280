.p-0 { padding:0; }
.p-1 { padding:.25rem; }
.p-2 { padding:.5rem; }
.p-3 { padding:1rem; }
.p-4 { padding:1.5rem; }
.p-5 { padding:3rem; }

.pt-0 { padding-top:0; }
.pr-0 { padding-right:0; }
.pb-0 { padding-bottom:0; }
.pl-0 { padding-left:0; }                             
.px-0 { padding-left:0;padding-right:0; }
.py-0 { padding-top:0;padding-bottom:0; }

.pt-1 { padding-top:.25rem; }         
.pr-1 { padding-right:.25rem; }                       
.pb-1 { padding-bottom:.25rem; }      
.pl-1 { padding-left:.25rem; }                            
.px-1 { padding-left:.25rem;padding-right:.25rem; }
.py-1 { padding-top:.25rem;padding-bottom:.25rem; }

.pt-2 { padding-top:.5rem; }                                              
.pr-2 { padding-right:.5rem; }                                
.pb-2 { padding-bottom:.5rem; }               
.pl-2 { padding-left:.5rem; }                                             
.px-2 { padding-right:.5rem;padding-left:.5rem; }
.py-2 { padding-top:.5rem;padding-bottom:.5rem; }

.pt-3 { padding-top:1rem; }                               
.pr-3 { padding-right:1rem; }             
.pb-3 { padding-bottom:1rem; }                
.pl-3 { padding-left:1rem; }                              
.py-3 { padding-bottom:1rem;padding-top:1rem; }
.px-3 { padding-right:1rem;padding-left:1rem; }

.pt-4 { padding-top:1.5rem; }                             
.pr-4 { padding-right:1.5rem; }               
.pb-4 { padding-bottom:1.5rem; }              
.pl-4 { padding-left:1.5rem; }                                
.px-4 { padding-right:1.5rem;padding-left:1.5rem; }
.py-4 { padding-top:1.5rem;padding-bottom:1.5rem; }

.pt-5 { padding-top:3rem; }   
.pr-5 { padding-right:3rem; } 
.pb-5 { padding-bottom:3rem; }    
.pl-5 { padding-left:3rem; }  
.px-5 { padding-right:3rem;padding-left:3rem; }
.py-5 { padding-top:3rem;padding-bottom:3rem; }

@include media-breakpoint-up(md) {
  .p-md-0 { padding:0; }
  .p-md-1 { padding:.25rem; }
  .p-md-2 { padding:.5rem; }
  .p-md-3 { padding:1rem; }
  .p-md-4 { padding:1.5rem; }
  .p-md-5 { padding:3rem; }

  .pt-md-0 { padding-top:0; }
  .pr-md-0 { padding-right:0; }
  .pb-md-0 { padding-bottom:0; }
  .pl-md-0 { padding-left:0; }                             
  .px-md-0 { padding-left:0;padding-right:0; }
  .py-md-0 { padding-top:0;padding-bottom:0; }

  .pt-md-1 { padding-top:.25rem; }         
  .pr-md-1 { padding-right:.25rem; }                       
  .pb-md-1 { padding-bottom:.25rem; }      
  .pl-md-1 { padding-left:.25rem; }                            
  .px-md-1 { padding-left:.25rem;padding-right:.25rem; }
  .py-md-1 { padding-top:.25rem;padding-bottom:.25rem; }

  .pt-md-2 { padding-top:.5rem; }                                              
  .pr-md-2 { padding-right:.5rem; }                                
  .pb-md-2 { padding-bottom:.5rem; }               
  .pl-md-2 { padding-left:.5rem; }                                             
  .px-md-2 { padding-right:.5rem;padding-left:.5rem; }
  .py-md-2 { padding-top:.5rem;padding-bottom:.5rem; }

  .pt-md-3 { padding-top:1rem; }                               
  .pr-md-3 { padding-right:1rem; }             
  .pb-md-3 { padding-bottom:1rem; }                
  .pl-md-3 { padding-left:1rem; }                              
  .py-md-3 { padding-bottom:1rem;padding-top:1rem; }
  .px-md-3 { padding-right:1rem;padding-left:1rem; }

  .pt-md-4 { padding-top:1.5rem; }                             
  .pr-md-4 { padding-right:1.5rem; }               
  .pb-md-4 { padding-bottom:1.5rem; }              
  .pl-md-4 { padding-left:1.5rem; }                                
  .px-md-4 { padding-right:1.5rem;padding-left:1.5rem; }
  .py-md-4 { padding-top:1.5rem;padding-bottom:1.5rem; }

  .pt-md-5 { padding-top:3rem; }   
  .pr-md-5 { padding-right:3rem; } 
  .pb-md-5 { padding-bottom:3rem; }    
  .pl-md-5 { padding-left:3rem; }  
  .px-md-5 { padding-right:3rem;padding-left:3rem; }
  .py-md-5 { padding-top:3rem;padding-bottom:3rem; }
}

@include media-breakpoint-up(lg) {
  .p-lg-0 { padding:0; }
  .p-lg-1 { padding:.25rem; }
  .p-lg-2 { padding:.5rem; }
  .p-lg-3 { padding:1rem; }
  .p-lg-4 { padding:1.5rem; }
  .p-lg-5 { padding:3rem; }

  .pt-lg-0 { padding-top:0; }
  .pr-lg-0 { padding-right:0; }
  .pb-lg-0 { padding-bottom:0; }
  .pl-lg-0 { padding-left:0; }                             
  .px-lg-0 { padding-left:0;padding-right:0; }
  .py-lg-0 { padding-top:0;padding-bottom:0; }

  .pt-lg-1 { padding-top:.25rem; }         
  .pr-lg-1 { padding-right:.25rem; }                       
  .pb-lg-1 { padding-bottom:.25rem; }      
  .pl-lg-1 { padding-left:.25rem; }                            
  .px-lg-1 { padding-left:.25rem;padding-right:.25rem; }
  .py-lg-1 { padding-top:.25rem;padding-bottom:.25rem; }

  .pt-lg-2 { padding-top:.5rem; }                                              
  .pr-lg-2 { padding-right:.5rem; }                                
  .pb-lg-2 { padding-bottom:.5rem; }               
  .pl-lg-2 { padding-left:.5rem; }                                             
  .px-lg-2 { padding-right:.5rem;padding-left:.5rem; }
  .py-lg-2 { padding-top:.5rem;padding-bottom:.5rem; }

  .pt-lg-3 { padding-top:1rem; }                               
  .pr-lg-3 { padding-right:1rem; }             
  .pb-lg-3 { padding-bottom:1rem; }                
  .pl-lg-3 { padding-left:1rem; }                              
  .py-lg-3 { padding-bottom:1rem;padding-top:1rem; }
  .px-lg-3 { padding-right:1rem;padding-left:1rem; }

  .pt-lg-4 { padding-top:1.5rem; }                             
  .pr-lg-4 { padding-right:1.5rem; }               
  .pb-lg-4 { padding-bottom:1.5rem; }              
  .pl-lg-4 { padding-left:1.5rem; }                                
  .px-lg-4 { padding-right:1.5rem;padding-left:1.5rem; }
  .py-lg-4 { padding-top:1.5rem;padding-bottom:1.5rem; }

  .pt-lg-5 { padding-top:3rem; }   
  .pr-lg-5 { padding-right:3rem; } 
  .pb-lg-5 { padding-bottom:3rem; }    
  .pl-lg-5 { padding-left:3rem; }  
  .px-lg-5 { padding-right:3rem;padding-left:3rem; }
  .py-lg-5 { padding-top:3rem;padding-bottom:3rem; }
}

@include media-breakpoint-up(xl) {
  .p-xl-0 { padding:0; }
  .p-xl-1 { padding:.25rem; }
  .p-xl-2 { padding:.5rem; }
  .p-xl-3 { padding:1rem; }
  .p-xl-4 { padding:1.5rem; }
  .p-xl-5 { padding:3rem; }

  .pt-xl-0 { padding-top:0; }
  .pr-xl-0 { padding-right:0; }
  .pb-xl-0 { padding-bottom:0; }
  .pl-xl-0 { padding-left:0; }                             
  .px-xl-0 { padding-left:0;padding-right:0; }
  .py-xl-0 { padding-top:0;padding-bottom:0; }

  .pt-xl-1 { padding-top:.25rem; }         
  .pr-xl-1 { padding-right:.25rem; }                       
  .pb-xl-1 { padding-bottom:.25rem; }      
  .pl-xl-1 { padding-left:.25rem; }                            
  .px-xl-1 { padding-left:.25rem;padding-right:.25rem; }
  .py-xl-1 { padding-top:.25rem;padding-bottom:.25rem; }

  .pt-xl-2 { padding-top:.5rem; }                                              
  .pr-xl-2 { padding-right:.5rem; }                                
  .pb-xl-2 { padding-bottom:.5rem; }               
  .pl-xl-2 { padding-left:.5rem; }                                             
  .px-xl-2 { padding-right:.5rem;padding-left:.5rem; }
  .py-xl-2 { padding-top:.5rem;padding-bottom:.5rem; }

  .pt-xl-3 { padding-top:1rem; }                               
  .pr-xl-3 { padding-right:1rem; }             
  .pb-xl-3 { padding-bottom:1rem; }                
  .pl-xl-3 { padding-left:1rem; }                              
  .py-xl-3 { padding-bottom:1rem;padding-top:1rem; }
  .px-xl-3 { padding-right:1rem;padding-left:1rem; }

  .pt-xl-4 { padding-top:1.5rem; }                             
  .pr-xl-4 { padding-right:1.5rem; }               
  .pb-xl-4 { padding-bottom:1.5rem; }              
  .pl-xl-4 { padding-left:1.5rem; }                                
  .px-xl-4 { padding-right:1.5rem;padding-left:1.5rem; }
  .py-xl-4 { padding-top:1.5rem;padding-bottom:1.5rem; }

  .pt-xl-5 { padding-top:3rem; }   
  .pr-xl-5 { padding-right:3rem; } 
  .pb-xl-5 { padding-bottom:3rem; }    
  .pl-xl-5 { padding-left:3rem; }  
  .px-xl-5 { padding-right:3rem;padding-left:3rem; }
  .py-xl-5 { padding-top:3rem;padding-bottom:3rem; }
}

@include media-breakpoint-up(xxl) {
  .p-xxl-0 { padding:0; }
  .p-xxl-1 { padding:.25rem; }
  .p-xxl-2 { padding:.5rem; }
  .p-xxl-3 { padding:1rem; }
  .p-xxl-4 { padding:1.5rem; }
  .p-xxl-5 { padding:3rem; }

  .pt-xxl-0 { padding-top:0; }
  .pr-xxl-0 { padding-right:0; }
  .pb-xxl-0 { padding-bottom:0; }
  .pl-xxl-0 { padding-left:0; }                             
  .px-xxl-0 { padding-left:0;padding-right:0; }
  .py-xxl-0 { padding-top:0;padding-bottom:0; }

  .pt-xxl-1 { padding-top:.25rem; }         
  .pr-xxl-1 { padding-right:.25rem; }                       
  .pb-xxl-1 { padding-bottom:.25rem; }      
  .pl-xxl-1 { padding-left:.25rem; }                            
  .px-xxl-1 { padding-left:.25rem;padding-right:.25rem; }
  .py-xxl-1 { padding-top:.25rem;padding-bottom:.25rem; }

  .pt-xxl-2 { padding-top:.5rem; }                                              
  .pr-xxl-2 { padding-right:.5rem; }                                
  .pb-xxl-2 { padding-bottom:.5rem; }               
  .pl-xxl-2 { padding-left:.5rem; }                                             
  .px-xxl-2 { padding-right:.5rem;padding-left:.5rem; }
  .py-xxl-2 { padding-top:.5rem;padding-bottom:.5rem; }

  .pt-xxl-3 { padding-top:1rem; }                               
  .pr-xxl-3 { padding-right:1rem; }             
  .pb-xxl-3 { padding-bottom:1rem; }                
  .pl-xxl-3 { padding-left:1rem; }                              
  .py-xxl-3 { padding-bottom:1rem;padding-top:1rem; }
  .px-xxl-3 { padding-right:1rem;padding-left:1rem; }

  .pt-xxl-4 { padding-top:1.5rem; }                             
  .pr-xxl-4 { padding-right:1.5rem; }               
  .pb-xxl-4 { padding-bottom:1.5rem; }              
  .pl-xxl-4 { padding-left:1.5rem; }                                
  .px-xxl-4 { padding-right:1.5rem;padding-left:1.5rem; }
  .py-xxl-4 { padding-top:1.5rem;padding-bottom:1.5rem; }

  .pt-xxl-5 { padding-top:3rem; }   
  .pr-xxl-5 { padding-right:3rem; } 
  .pb-xxl-5 { padding-bottom:3rem; }    
  .pl-xxl-5 { padding-left:3rem; }  
  .px-xxl-5 { padding-right:3rem;padding-left:3rem; }
  .py-xxl-5 { padding-top:3rem;padding-bottom:3rem; }
}