.ratio-1-1{aspect-ratio: 1 / 1;}
.ratio-2-3{aspect-ratio: 2 / 3;}
.ratio-3-2{aspect-ratio: 3 / 2;}
.ratio-9-16{aspect-ratio: 9 / 16;}
.ratio-16-9{aspect-ratio: 16 / 9;}

@include media-breakpoint-up(md) {
  .ratio-md-1-1{aspect-ratio: 1 / 1;}
  .ratio-md-2-3{aspect-ratio: 2 / 3;}
  .ratio-md-3-2{aspect-ratio: 3 / 2;}
  .ratio-md-9-16{aspect-ratio: 9 / 16;}
  .ratio-md-16-9{aspect-ratio: 16 / 9;}
}

@include media-breakpoint-up(lg) {
  .ratio-lg-1-1{aspect-ratio: 1 / 1;}
  .ratio-lg-2-3{aspect-ratio: 2 / 3;}
  .ratio-lg-3-2{aspect-ratio: 3 / 2;}
  .ratio-lg-9-16{aspect-ratio: 9 / 16;}
  .ratio-lg-16-9{aspect-ratio: 16 / 9;}
}

@include media-breakpoint-up(xl) {
  .ratio-xl-1-1{aspect-ratio: 1 / 1;}
  .ratio-xl-2-3{aspect-ratio: 2 / 3;}
  .ratio-xl-3-2{aspect-ratio: 3 / 2;}
  .ratio-xl-9-16{aspect-ratio: 9 / 16;}
  .ratio-xl-16-9{aspect-ratio: 16 / 9;}
}

@include media-breakpoint-up(xxl) {
  .ratio-xxl-1-1{aspect-ratio: 1 / 1;}
  .ratio-xxl-2-3{aspect-ratio: 2 / 3;}
  .ratio-xxl-3-2{aspect-ratio: 3 / 2;}
  .ratio-xxl-9-16{aspect-ratio: 9 / 16;}
  .ratio-xxl-16-9{aspect-ratio: 16 / 9;}
}