.flex-wrap{flex-wrap: wrap;}
.rounded{border-radius:50%;}
.cursor-pointer{cursor:pointer;}
.outline-none{outline:none;}
.decoration-none{text-decoration:none;}
.bold{font-weight:bold;}
.stretched-link{
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
}
