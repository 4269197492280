@import "../colors";

.campaign_create {
  label {
    color: $color_9;
    white-space: nowrap;
  }

  .full-stream-checkbox {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    accent-color: $color_4;
  }

  .add-ad-unit-button {
    min-width: 50px;
    background-color: $color_4;
    border-radius: 5px;
  }
  .save-button {
    border-radius: 3px;
    background-color: $color_4;
    font-size: 1.2rem;
  }
  .generate-link-button {
    border-radius: 3px;
    font-size: 1.2rem;
    background-color: #00ff6d;
  }
  .generate-preview-button {
    border: 2px solid $danger;
    background-color: transparent;
    color: $danger;
    font-size: 1.2rem;
    border-radius: 3px;
  }
}
