.h-5{height:5%}
.h-10{height:10%}
.h-15{height:15%}
.h-20{height:20%}
.h-25{height:25%}
.h-30{height:30%}
.h-35{height:35%}
.h-40{height:40%}
.h-45{height:45%}
.h-50{height:50%}
.h-55{height:55%}
.h-60{height:60%}
.h-65{height:65%}
.h-70{height:70%}
.h-75{height:75%}
.h-80{height:80%}
.h-85{height:85%}
.h-90{height:90%}
.h-95{height:95%}
.h-100{height:100%}
.h-auto{height:auto}

@include media-breakpoint-up(md) {
  .h-md-5{height:5%}
  .h-md-10{height:10%}
  .h-md-15{height:15%}
  .h-md-20{height:20%}
  .h-md-25{height:25%}
  .h-md-30{height:30%}
  .h-md-35{height:35%}
  .h-md-40{height:40%}
  .h-md-45{height:45%}
  .h-md-50{height:50%}
  .h-md-55{height:55%}
  .h-md-60{height:60%}
  .h-md-65{height:65%}
  .h-md-70{height:70%}
  .h-md-75{height:75%}
  .h-md-80{height:80%}
  .h-md-85{height:85%}
  .h-md-90{height:90%}
  .h-md-95{height:95%}
  .h-md-100{height:100%}
  .h-auto{height:auto}
}

@include media-breakpoint-up(lg) {
  .h-lg-5{height:5%}
  .h-lg-10{height:10%}
  .h-lg-15{height:15%}
  .h-lg-20{height:20%}
  .h-lg-25{height:25%}
  .h-lg-30{height:30%}
  .h-lg-35{height:35%}
  .h-lg-40{height:40%}
  .h-lg-45{height:45%}
  .h-lg-50{height:50%}
  .h-lg-55{height:55%}
  .h-lg-60{height:60%}
  .h-lg-65{height:65%}
  .h-lg-70{height:70%}
  .h-lg-75{height:75%}
  .h-lg-80{height:80%}
  .h-lg-85{height:85%}
  .h-lg-90{height:90%}
  .h-lg-95{height:95%}
  .h-lg-100{height:100%}
  .h-auto{height:auto}
}

@include media-breakpoint-up(xl) {
  .h-xl-5{height:5%}
  .h-xl-10{height:10%}
  .h-xl-15{height:15%}
  .h-xl-20{height:20%}
  .h-xl-25{height:25%}
  .h-xl-30{height:30%}
  .h-xl-35{height:35%}
  .h-xl-40{height:40%}
  .h-xl-45{height:45%}
  .h-xl-50{height:50%}
  .h-xl-55{height:55%}
  .h-xl-60{height:60%}
  .h-xl-65{height:65%}
  .h-xl-70{height:70%}
  .h-xl-75{height:75%}
  .h-xl-80{height:80%}
  .h-xl-85{height:85%}
  .h-xl-90{height:90%}
  .h-xl-95{height:95%}
  .h-xl-100{height:100%}
  .h-auto{height:auto}
}

@include media-breakpoint-up(xxl) {
  .h-xxl-5{height:5%}
  .h-xxl-10{height:10%}
  .h-xxl-15{height:15%}
  .h-xxl-20{height:20%}
  .h-xxl-25{height:25%}
  .h-xxl-30{height:30%}
  .h-xxl-35{height:35%}
  .h-xxl-40{height:40%}
  .h-xxl-45{height:45%}
  .h-xxl-50{height:50%}
  .h-xxl-55{height:55%}
  .h-xxl-60{height:60%}
  .h-xxl-65{height:65%}
  .h-xxl-70{height:70%}
  .h-xxl-75{height:75%}
  .h-xxl-80{height:80%}
  .h-xxl-85{height:85%}
  .h-xxl-90{height:90%}
  .h-xxl-95{height:95%}
  .h-xxl-100{height:100%}
  .h-auto{height:auto}
}