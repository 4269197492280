.position-static{ position: static; }
.position-relative{ position: relative; }
.position-fixed{ position: fixed; }
.position-absolute{ position: absolute; }
.position-sticky{ position: sticky; }

@include media-breakpoint-up(md) {
  .position-md-static{ position: static; }
  .position-md-relative{ position: relative; }
  .position-md-fixed{ position: fixed; }
  .position-md-absolute{ position: absolute; }
  .position-md-sticky{ position: sticky; }
}

@include media-breakpoint-up(lg) {
  .position-lg-static{ position: static; }
  .position-lg-relative{ position: relative; }
  .position-lg-fixed{ position: fixed; }
  .position-lg-absolute{ position: absolute; }
  .position-lg-sticky{ position: sticky; }
}

@include media-breakpoint-up(xl) {
  .position-xl-static{ position: static; }
  .position-xl-relative{ position: relative; }
  .position-xl-fixed{ position: fixed; }
  .position-xl-absolute{ position: absolute; }
  .position-xl-sticky{ position: sticky; }
}

@include media-breakpoint-up(xxl) {
  .position-xxl-static{ position: static; }
  .position-xxl-relative{ position: relative; }
  .position-xxl-fixed{ position: fixed; }
  .position-xxl-absolute{ position: absolute; }
  .position-xxl-sticky{ position: sticky; }
}