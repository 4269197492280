.center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-x{
  display: flex;
  justify-content: center;
}
.center-y{
  display: flex;
  align-items: center;
}
.center-row{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow:row;
}
.center-column{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow:column;
}
.center-x-column{
  display: flex;
  justify-content: center;
  flex-flow:column;
}
.center-x-row{
  display: flex;
  justify-content: center;
  flex-flow:row;
}
.center-y-column{
  display: flex;
  align-items: center;
  flex-flow:column;
}
.center-y-row{
  display: flex;
  align-items: center;
  flex-flow:row;
}

@include media-breakpoint-up(md) {
  .center-md{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .center-md-x{
    display: flex;
    justify-content: center;
  }
  .center-md-y{
    display: flex;
    align-items: center;
  }
  .center-md-row{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow:row;
  }
  .center-md-column{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow:column;
  }
  .center-md-x-column{
    display: flex;
    justify-content: center;
    flex-flow:column;
  }
  .center-md-x-row{
    display: flex;
    justify-content: center;
    flex-flow:row;
  }
  .center-md-y-column{
    display: flex;
    align-items: center;
    flex-flow:column;
  }
  .center-md-y-row{
    display: flex;
    align-items: center;
    flex-flow:row;
  }
}

@include media-breakpoint-up(lg) {
  .center-lg{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .center-lg-x{
    display: flex;
    justify-content: center;
  }
  .center-lg-y{
    display: flex;
    align-items: center;
  }
  .center-lg-row{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow:row;
  }
  .center-lg-column{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow:column;
  }
  .center-lg-x-column{
    display: flex;
    justify-content: center;
    flex-flow:column;
  }
  .center-lg-x-row{
    display: flex;
    justify-content: center;
    flex-flow:row;
  }
  .center-lg-y-column{
    display: flex;
    align-items: center;
    flex-flow:column;
  }
  .center-lg-y-row{
    display: flex;
    align-items: center;
    flex-flow:row;
  }
}

@include media-breakpoint-up(xl) {
  .center-xl{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .center-xl-x{
    display: flex;
    justify-content: center;
  }
  .center-xl-y{
    display: flex;
    align-items: center;
  }
  .center-xl-row{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow:row;
  }
  .center-xl-column{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow:column;
  }
  .center-xl-x-column{
    display: flex;
    justify-content: center;
    flex-flow:column;
  }
  .center-xl-x-row{
    display: flex;
    justify-content: center;
    flex-flow:row;
  }
  .center-xl-y-column{
    display: flex;
    align-items: center;
    flex-flow:column;
  }
  .center-xl-y-row{
    display: flex;
    align-items: center;
    flex-flow:row;
  }
}

@include media-breakpoint-up(xxl) {
  .center-xxl{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .center-xxl-x{
    display: flex;
    justify-content: center;
  }
  .center-xxl-y{
    display: flex;
    align-items: center;
  }
  .center-xxl-row{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow:row;
  }
  .center-xxl-column{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow:column;
  }
  .center-xxl-x-column{
    display: flex;
    justify-content: center;
    flex-flow:column;
  }
  .center-xxl-x-row{
    display: flex;
    justify-content: center;
    flex-flow:row;
  }
  .center-xxl-y-column{
    display: flex;
    align-items: center;
    flex-flow:column;
  }
  .center-xxl-y-row{
    display: flex;
    align-items: center;
    flex-flow:row;
  }
}