@import "../colors";

.ad_unit_create {
  .banner-position {
    min-width: 200px;
    background-color: $color_2;
    height: 110px;
    .center-input {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    > div {
      border-radius: 3px;
      background-color: $color_3;
    }
  }
}
