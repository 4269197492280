.border-none{border:none;}
.border-top-none{border-top:none;}
.border-bottom-none{border-bottom:none;}
.border-left-none{border-left:none;}
.border-right-none{border-right:none;}

@include media-breakpoint-up(md) {
  .border-md-none{border:none;}
  .border-md-top-none{border-top:none;}
  .border-md-bottom-none{border-bottom:none;}
  .border-md-left-none{border-left:none;}
  .border-md-right-none{border-right:none;}
}

@include media-breakpoint-up(lg) {
  .border-lg-none{border:none;}
  .border-lg-top-none{border-top:none;}
  .border-lg-bottom-none{border-bottom:none;}
  .border-lg-left-none{border-left:none;}
  .border-lg-right-none{border-right:none;}
}

@include media-breakpoint-up(xl) {
  .border-xl-none{border:none;}
  .border-xl-top-none{border-top:none;}
  .border-xl-bottom-none{border-bottom:none;}
  .border-xl-left-none{border-left:none;}
  .border-xl-right-none{border-right:none;}
}

@include media-breakpoint-up(xxl) {
  .border-xxl-none{border:none;}
  .border-xxl-top-none{border-top:none;}
  .border-xxl-bottom-none{border-bottom:none;}
  .border-xxl-left-none{border-left:none;}
  .border-xxl-right-none{border-right:none;}
}